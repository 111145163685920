import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import VanillaTilt from "vanilla-tilt";

import { useMediaQueryContext } from "lib/mediaQueryContext";
import { TiltCardStyled, Image } from "./TiltCard.styled";

const options = {
    reverse: true,
    max: 12,
    startX: -20,
    startY: 20,
    scale: 1,
    reset: true,
    easing: "cubic-bezier(0.090, 0.900, 0.955, 0.505",
    glare: true,
    "max-glare": 0.12,
    "glare-prerender": false,
    "full-page-listening": true,
    gyroscope: true,
    gyroscopeMinAngleX: -45,
    gyroscopeMaxAngleX: 45,
    gyroscopeMinAngleY: -45,
    gyroscopeMaxAngleY: 45
};
function TiltCard({ url, alt, borderRadius }) {
    const { mobileView, desktopView } = useMediaQueryContext();
    const cardRef = useRef();

    useEffect(() => {
        const currentCardRef = cardRef.current;

        if (currentCardRef) VanillaTilt.init(cardRef.current, options);

        return () => {
            if (currentCardRef && currentCardRef.vanillaTilt) {
                currentCardRef.vanillaTilt.destroy();
            }
        };
    }, [cardRef]);

    return (
        <>
            {mobileView && (
                <Image
                    src={url}
                    alt={alt}
                    width={280}
                    height={182}
                    priority
                    placeholder="blur"
                    blurDataURL="/images/blur.png"
                />
            )}
            {desktopView && (
                <TiltCardStyled ref={cardRef} borderRadius={borderRadius}>
                    <Image
                        src={url}
                        alt={alt}
                        width={499}
                        height={324}
                        priority
                        placeholder="blur"
                        blurDataURL="/images/blur.png"
                    />
                </TiltCardStyled>
            )}
        </>
    );
}

TiltCard.propTypes = {
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
    borderRadius: PropTypes.string
};

TiltCard.defaultProps = {
    alt: "",
    borderRadius: "0"
};

export default TiltCard;
