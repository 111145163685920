import PropTypes from "prop-types";

import {
    Primary,
    PrimaryBig,
    Secondary,
    SecondaryLarge,
    SecondarySmall,
    Tertiary,
    TertiaryBig
} from "./Title.styled";

export default function Title({
    variant,
    children,
    className,
    leftAlignedMobile,
    leftAlignedDesktop
}) {
    if (variant === "primaryBig") {
        return (
            <PrimaryBig
                className={className}
                leftAlignedMobile={leftAlignedMobile}
                leftAlignedDesktop={leftAlignedDesktop}
            >
                {children}
            </PrimaryBig>
        );
    }

    if (variant === "secondary") {
        return (
            <Secondary
                className={className}
                leftAlignedMobile={leftAlignedMobile}
                leftAlignedDesktop={leftAlignedDesktop}
            >
                {children}
            </Secondary>
        );
    }

    if (variant === "secondaryLarge") {
        return (
            <SecondaryLarge
                className={className}
                leftAlignedMobile={leftAlignedMobile}
                leftAlignedDesktop={leftAlignedDesktop}
            >
                {children}
            </SecondaryLarge>
        );
    }

    if (variant === "secondarySmall") {
        return (
            <SecondarySmall
                className={className}
                leftAlignedMobile={leftAlignedMobile}
                leftAlignedDesktop={leftAlignedDesktop}
            >
                {children}
            </SecondarySmall>
        );
    }

    if (variant === "tertiary") {
        return (
            <Tertiary
                className={className}
                leftAlignedMobile={leftAlignedMobile}
                leftAlignedDesktop={leftAlignedDesktop}
            >
                {children}
            </Tertiary>
        );
    }

    if (variant === "tertiaryBig") {
        return (
            <TertiaryBig
                className={className}
                leftAlignedMobile={leftAlignedMobile}
                leftAlignedDesktop={leftAlignedDesktop}
            >
                {children}
            </TertiaryBig>
        );
    }

    return (
        <Primary
            className={className}
            leftAlignedMobile={leftAlignedMobile}
            leftAlignedDesktop={leftAlignedDesktop}
        >
            {children}
        </Primary>
    );
}

Title.propTypes = {
    variant: PropTypes.oneOf([
        "primary",
        "primaryBig",
        "secondary",
        "secondaryLarge",
        "secondarySmall",
        "tertiary",
        "tertiaryBig"
    ]).isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    leftAlignedMobile: PropTypes.bool,
    leftAlignedDesktop: PropTypes.bool
};

Title.defaultProps = {
    className: "",
    leftAlignedMobile: false,
    leftAlignedDesktop: false
};
