import PropTypes from "prop-types";
import Button from "components/shared/button/Button";
import Image from "next/image";
import CardsImg from "public/images/cards.jpg";
import {
    InstructionsStyled,
    Container,
    Heading,
    Text,
    Buttons,
    Cards
} from "./Instructions.styled";

function Instructions({ data }) {
    const content = data?.[0];
    return (
        <InstructionsStyled>
            <Container>
                <Heading>{content?.title}</Heading>
                <Text>{content?.description}</Text>
                <Buttons>
                    <Button
                        variant="regular"
                        element="a"
                        href="https://www.cadeaubon.nl/cadeaukaart-keuze/step-one"
                        target="_blank"
                        rel="noopener norefer"
                    >
                        Inwisselen
                    </Button>
                    <Button variant="underline" element="a" href="/saldocheck">
                        Saldo checken
                    </Button>
                </Buttons>
            </Container>
            <Cards>
                <Image src={CardsImg} alt="Cards" />
            </Cards>
        </InstructionsStyled>
    );
}

Instructions.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string
        })
    )
};

Instructions.defaultProps = {
    data: [
        {
            title: "Keuzekaart ontvangen?",
            description:
                "Gefeliciteerd! Jij kunt nu kiezen uit meer dan 100 verschillende cadeaukaarten of goede doelen. Het saldo op de kaart mag je in delen inwisselen. Waar ga jij voor?"
        }
    ]
};

export default Instructions;
