import { useRef } from "react";
import PropTypes from "prop-types";

import { ContactFormProvider } from "lib/contactFormContext";
import ContactFormModal from "components/shared/contactForm/modal/Modal";
import { LandingStyled } from "./Landing.styled";
import Hero from "./hero/Hero";
import Brands from "./brands/Brands";
import Links from "./links/Links";
import Steps from "./steps/Steps";
import Instructions from "./instructions/Instructions";
import BusinessGift from "./businessGift/BusinessGift";
import Information from "./information/Information";

import Resellers from "./resellers/Resellers";

function Landing({ data }) {
    const refInformation = useRef(null);

    const usage = data?.sections?.usage;
    const resellers = data?.sections?.resellers;
    const doubler = data?.sections?.doubler;
    const business = data?.sections?.business;

    return (
        <LandingStyled>
            <Hero data={data?.hero} />
            <Brands />
            <Links data={data?.usps} />
            <Steps data={data?.steps} />
            {usage && <Instructions data={data?.received} />}
            <ContactFormProvider>
                {business && <BusinessGift data={data?.business} />}
                <ContactFormModal />
            </ContactFormProvider>
            {resellers && <Resellers />}
            <Information
                ref={refInformation}
                doubler={doubler}
                seo={data?.seo?.first}
            />
        </LandingStyled>
    );
}

Landing.propTypes = {
    data: PropTypes.shape({
        hero: PropTypes.object,
        steps: PropTypes.array,
        business: PropTypes.array,
        received: PropTypes.array,
        usps: PropTypes.array,
        seo: PropTypes.shape({
            first: PropTypes.object
        }),
        sections: PropTypes.shape({
            business: PropTypes.bool,
            doubler: PropTypes.bool,
            resellers: PropTypes.bool,
            restaurants: PropTypes.bool,
            themes: PropTypes.bool,
            themesSlider: PropTypes.bool,
            usage: PropTypes.bool
        })
    })
};

Landing.defaultProps = {
    data: {
        hero: undefined,
        steps: undefined,
        business: undefined,
        received: undefined,
        usps: undefined,
        sections: {
            business: true,
            doubler: true,
            resellers: true,
            restaurants: true,
            themes: true,
            themesSlider: true,
            usage: true
        }
    }
};

export default Landing;
