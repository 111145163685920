import styled from "styled-components";
import media from "constants/media";

export const Wrapper = styled.div`
    background-color: var(--colors-white);
`;

export const Container = styled.div`
    display: flex;

    flex-direction: column;

    width: 90%;
    max-width: 320px;
    margin: 0 auto;
    padding: 10px 0 180px;

    text-align: left;

    ${media.mdUp`
        display: grid;
        grid-template-areas:
            "header image"
            "benefits image";
        width: 100%;
        max-width: 1140px;
        margin: auto;
        padding: 10px 2% 180px;
    `}

    ${media.lgUp`
        padding: 10px 0 180px;
    `}
`;

export const Header = styled.header`
    grid-area: header;
    max-width: 580px;
`;

export const ImageContainer = styled.div`
    position: relative;

    display: block;
    grid-area: image;
    width: 255px;
    height: 383px;
    margin: 0 auto;

    ${media.mdUp`
        width:536px;
        height: 805px;
    `}
`;
