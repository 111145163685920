import NukaCarousel from "nuka-carousel";
import PropTypes from "prop-types";

import { CarouselStyled, Button } from "./Carousel.styled";

function Carousel({
    isLoading,
    items,
    slideWidth,
    noDots,
    noButtons,
    buttonsOutside
}) {
    return items && items.length > 0 ? (
        <CarouselStyled
            isLoading={isLoading}
            noDots={noDots}
            noButtons={noButtons}
            buttonsOutside={buttonsOutside}
        >
            <NukaCarousel
                cellSpacing={slideWidth === 1 ? 0 : 20}
                defaultControlsConfig={{
                    pagingDotsContainerClassName:
                        "slider-dots-container-custom",
                    pagingDotsClassName: "slider-dots-dot-custom"
                }}
                easing="easeSin"
                frameOverflow="visible"
                slideWidth={slideWidth}
                speed={300}
                swiping
                width="100%"
                renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                    noButtons ? null : (
                        <Button
                            type="button"
                            aria-label="Vorige item"
                            left
                            onClick={previousSlide}
                            disabled={isLoading || currentSlide === 0}
                            buttonsOutside={buttonsOutside}
                        >
                            Prev
                        </Button>
                    )
                }
                renderCenterRightControls={({
                    nextSlide,
                    currentSlide,
                    slideCount
                }) =>
                    noButtons ? null : (
                        <Button
                            type="button"
                            aria-label="Volgende item"
                            right
                            onClick={nextSlide}
                            disabled={
                                isLoading || currentSlide === slideCount - 1
                            }
                        >
                            Next
                        </Button>
                    )
                }
            >
                {items}
            </NukaCarousel>
        </CarouselStyled>
    ) : null;
}

Carousel.propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    slideWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    noDots: PropTypes.bool,
    noButtons: PropTypes.bool,
    buttonsOutside: PropTypes.bool
};

Carousel.defaultProps = {
    isLoading: false,
    items: [],
    slideWidth: "348px",
    noDots: false,
    noButtons: false,
    buttonsOutside: false
};

export default Carousel;
