import { useState } from "react";
import PropTypes from "prop-types";
import Button from "../button/Button";

import { Title, Text, Controls, Gradient } from "./ClampedText.styled";

const ClampedText = ({ title, text, linesVisible }) => {
    const [visibleLines, setVisibleLines] = useState(linesVisible);
    const [isShowingMore, setIsShowingMore] = useState(false);

    function showMoreText() {
        setIsShowingMore(!isShowingMore);

        if (isShowingMore) {
            setVisibleLines(linesVisible);
        } else {
            setVisibleLines(100);
        }
    }

    return (
        <div>
            <Title variant="primary">{title}</Title>
            <Text lines={visibleLines}>
                {/*  eslint-disable-next-line */}
                <span dangerouslySetInnerHTML={{ __html: text }} />
            </Text>
            <Controls>
                <Gradient isShowingMore={isShowingMore} />
                <Button variant="underline" onClick={showMoreText}>
                    {isShowingMore ? "Minder lezen" : "Verder lezen"}
                </Button>
            </Controls>
        </div>
    );
};

ClampedText.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    text: PropTypes.string,
    linesVisible: PropTypes.number
};

ClampedText.defaultProps = {
    title: "",
    text: "",
    linesVisible: 4
};

export default ClampedText;
