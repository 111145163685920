import { useContactFormContext } from "lib/contactFormContext";
import dynamic from "next/dynamic";

const Modal = dynamic(() => import("react-modal"));
const ContactForm = dynamic(() => import("../ContactForm"));

export default function ContactFormModal() {
    const { show, setShow } = useContactFormContext();
    const closeModal = () => setShow(false);
    return !show ? null : (
        <Modal
            isOpen={!!show}
            contentLabel={show?.label}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className="react-modal"
            overlayClassName="react-modal-overlay"
            closeTimeoutMS={400}
            shouldCloseOnOverlayClick
        >
            {show && (
                <ContactForm
                    type={show?.type}
                    contentLabel={show?.label}
                    closeModal={closeModal}
                />
            )}
        </Modal>
    );
}
