import PropTypes from "prop-types";
import NextImage from "next/image";

import { useMediaQueryContext } from "lib/mediaQueryContext";

import Title from "components/shared/title/Title";
import Text from "components/shared/text/Text";
import Benefits from "./benefits/Benefits";

import {
    Wrapper,
    Container,
    Header,
    ImageContainer
} from "./BusinessGift.styled";

function BusinessGift({ data }) {
    const { mobileView } = useMediaQueryContext();
    const content = data?.[0];
    return (
        <Wrapper>
            <Container>
                <Header>
                    <Title
                        variant="secondary"
                        leftAlignedDesktop
                        leftAlignedMobile
                    >
                        {content?.title}
                    </Title>
                    <Text leftAlignedDesktop leftAlignedMobile>
                        {content?.description}
                    </Text>
                </Header>
                <ImageContainer>
                    <NextImage
                        src="/images/home-cardstack.png"
                        width={mobileView ? 255 : 536}
                        height={mobileView ? 383 : 805}
                        alt="Card stack"
                    />
                </ImageContainer>
                <Benefits data={content?.sections} />
            </Container>
        </Wrapper>
    );
}

BusinessGift.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            images: PropTypes.arrayOf(
                PropTypes.shape({
                    url: PropTypes.string,
                    alt: PropTypes.string
                })
            ),
            sections: PropTypes.arrayOf(PropTypes.object)
        })
    )
};

BusinessGift.defaultProps = {
    data: [
        {
            title: "Hét ideale zakelijke cadeau",
            description:
                "Zoek je naar een leuk cadeau voor relaties of collega’s? Dan is de keuze cadeaukaart altijd de perfecte match. Laat jouw ontvanger zelf zijn of haar ideale cadeau uitkiezen.",
            images: [{ url: "/images/home-cardstack.png", alt: "Card stack" }],
            sections: undefined
        }
    ]
};
export default BusinessGift;
