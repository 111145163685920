import styled from "styled-components";
import media from "constants/media";

import Text from "components/shared/text/Text";
import SharedButton from "components/shared/button/Button";

export const Container = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    width: 95%;
    margin: -150px auto 0 0;
    padding: 41px 0 51px;

    color: var(--colors-white);

    background-color: var(--colors-bright-orange);

    border-radius: 0 var(--border-radius-large) var(--border-radius-large) 0;

    ${media.mdUp`
        position: relative;

        display: flex;
        justify-content: flex-start;
        margin: -150px auto 0;
        max-width: 1310px;
        padding: 105px 90px 105px 20px;
        width: 90%;


        &:before {
            position: absolute;
            top: 0;
            left: -130px;
            z-index: 0;
            
            display: block;
            width: 100%;
            height: 100%;
            
            background-color: var(--colors-bright-orange);
            content: "";
        }
    `}

    ${media.mdUp`
        padding-left: 0;
    `}
`;

export const Wrapper = styled.div`
    max-width: 310px;
    margin-left: 20px;

    ${media.mdUp`
        position: relative;
        z-index: 10;

        display: flex;
        justify-content: flex-start;
        max-width: 1140px;
        margin: auto;
    `}
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 480px;
    margin-left: 0;

    ${media.mdUp`
        width: 100%;
    `}
`;

export const Right = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 95%;
    max-width: 479px;
    margin: 28px 0 0;

    ${media.mdUp`
        margin-left: 100px;
        width: 100%;
    `}
`;

export const Description = styled(Text)`
    margin: 11px auto 24px;

    ${media.mdUp`
        margin: 0 auto 20px;
    `}
`;

export const Button = styled(SharedButton)`
    width: 221px;

    ${media.mdUp`
        width: 262px;
    `}
`;

export const ImageContainer = styled.div`
    width: 78px;

    ${media.mdUp`
        width: 120px;
    `}
`;
