import styled from "styled-components";
import media from "constants/media";

export const HeroStyled = styled.div`
    position: relative;

    width: 90%;
    max-width: 320px;
    margin: 38px auto 0;
    padding: 0;

    color: var(--colors-white);

    text-align: center;

    ${media.mdUp`
        width: 100%;
        max-width: 1180px;

        margin: 125px auto 0 auto;
        padding: 0 20px;
    `}
`;

export const Container = styled.div`
    ${media.mdUp`
        width: 50%;
    `}
`;

export const Heading = styled.h1`
    position: relative;
    z-index: 2;

    margin-bottom: 13px;

    font-weight: bold;
    font-size: 18px;
    line-height: 25px;

    ${media.mdUp`
        margin-bottom: 19px;

        font-size: 20px;
        line-height: 30px;

        text-align: left;
    `}
`;

export const Subheading = styled.h2`
    position: relative;
    z-index: 2;

    margin-bottom: 19px;

    font-weight: 300;
    font-size: 35px;
    line-height: 40px;

    ${media.mdUp`
        margin-bottom: 21px;

        font-size: 60px;
        line-height: 70px;

        text-align: left;
    `}
`;

export const Buttons = styled.div`
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 31px;

    ${media.mdUp`
        justify-content: flex-start;

        a {
            margin-right: 19px;
        }
    `}
`;

export const Image = styled.div`
    position: relative;

    margin-bottom: 40px;

    ${media.mdUp`
      position: absolute;
      right: 20px;
      top: 50%;

      width: 45%;
      max-width: 500px;

      transform: translateY(-50%);
    `}
`;

export const BackgroundImagesContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    transform: translate(-50%, -50%);

    ${media.mdUp`
        left: 40%;
        top: 42%;
    `}
`;

export const BackgroundImage = styled.div`
    position: relative;

    width: 505px;
    height: 110px;

    ${media.mdUp`
        height: 561px;
        width: 900px;
    `}
`;

export const Card = styled.div`
    position: relative;
    z-index: 2;

    width: 100%;
`;

export const Usps = styled.ul`
    position: relative;
    z-index: 2;
`;

export const UspImage = styled.div`
    position: relative;

    width: 20px;
    height: 20px;
`;

export const Usp = styled.li`
    display: flex;
    align-items: flex-start;

    font-size: 14px;
    line-height: 25px;

    ${media.mdUp`
        line-height: 30px;
    `}

    ${UspImage} {
        margin-right: 7px;

        ${media.mdUp`
            margin: 3px 7px 0 0;
        `}
    }

    span {
        text-align: left;
    }

    &:not(:last-child) {
        margin-bottom: 5px;
    }
`;
