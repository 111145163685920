import styled from "styled-components";
import media from "constants/media";

export const StepsStyled = styled.div`
    position: relative;
    z-index: 1;

    width: 100%;
    max-width: 320px;

    margin: 41px auto 0;

    ${media.mdUp`
        max-width: 1180px;

        margin: 72px auto 0 auto;
    `}
`;

export const BackgroundContainer = styled.div`
    position: relative;
`;

export const BackgroundImage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;

    width: 600px;

    transform: translate(-50%, -50%);

    ${media.mdUp`
        top: 55%;

        width: 900px;
    `}
`;

export const Heading = styled.h2`
    margin-bottom: 19px;

    color: var(--colors-white);
    font-size: 24px;
    line-height: 35px;
    text-align: center;

    ${media.mdUp`
        margin-bottom: 22px;

        font-size: 40px;
        line-height: 50px;
    `}
`;

export const Items = styled.div`
    display: flex;

    padding: 0 20px;
`;

export const Step = styled.div`
    height: 230px;
    padding: 28px 30px 20px;

    color: var(--colors-dark-blue);

    background: var(--colors-white);

    border-radius: 10px;

    ${media.mdUp`
        width: 32%;
        height: auto;
        min-height: 260px;
        padding: 50px 30px 50px;

        &:not(:last-child) {
          margin-right: 2%;
        }
    `}

    ${media.lgUp`
        padding: 50px;
    `}
`;

export const Title = styled.h3`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 6px;

    font-size: 18px;
    line-height: 25px;
    white-space: nowrap;

    ${media.mdUp`
        margin-bottom: 11px;

        font-size: 20px;
        line-height: 30px;
    `}
`;

export const Number = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    min-width: 25px;
    height: 25px;

    margin: -2px 9px 0 0;
    padding-top: 1px;

    color: var(--colors-white);
    font-size: 14px;
    line-height: 1;

    background: var(--colors-bright-orange);

    border-radius: 50%;
`;

export const Text = styled.p`
    font-size: 14px;
    line-height: 25px;
    text-align: center;

    ${media.mdUp`
        line-height: 30px;
    `}
`;
