import styled from "styled-components";
import media from "constants/media";

import SharedTitle from "components/shared/title/Title";
import SharedText from "components/shared/text/Text";

export const InformationStyled = styled.div`
    margin-top: 35px;
    padding-bottom: 55px;

    color: var(--colors-white);

    ${media.mdUp`
        margin-top: 100px;
        padding-bottom: 0;
    `}
`;

export const Container = styled.div`
    width: 100%;
    max-width: 320px;

    margin: 0 auto;
    padding: 0 20px;

    ${media.mdUp`
        max-width: 1180px;
    `}
`;

export const Heading = styled.h2`
    margin-bottom: 3px;

    font-size: 24px;
    line-height: 35px;

    ${media.mdUp`
        max-width: 580px;

        margin-bottom: 6px;

        font-size: 40px;
        line-height: 50px;
    `}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    ${media.mdUp`
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
    `}
`;

export const MoreInfo = styled.div`
    max-width: 480px;
`;

export const DoublerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 580px;
    margin-top: 15px;

    text-align: center;

    ${media.mdUp`
        margin: 0;
    `}
`;

export const DoublerImageContainer = styled.div`
    margin-left: -50px;

    ${media.mdUp`
        margin: -100px 0 0;
    `}
`;

export const Title = styled(SharedTitle)`
    max-width: 11em;

    ${media.mdUp`
        max-width: unset;
    `}
`;

export const Description = styled(SharedText)`
    max-width: 480px;
    margin: 10px 0;

    ${media.mdUp`
        margin: 7px 0 20px;
    `}
`;

export const Text = styled.p`
    margin-bottom: ${({ noMargin }) => (noMargin ? "0" : "5px")};

    font-size: 14px;
    line-height: 25px;

    ${media.mdUp`
        margin-bottom: ${({ noMargin }) => (noMargin ? "0" : "40px")};

        font-size: 14px;
        line-height: 30px;
    `}

    a {
        color: var(--colors-bright-orange);
        text-decoration: none;

        &:hover,
        &:focus {
            color: var(--colors-red);

            transition: 0.3s all ease-in-out;
        }
    }
`;
