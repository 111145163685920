import styled from "styled-components";
import media from "constants/media";

export const LinksStyled = styled.div`
    display: flex;
    flex-direction: column;

    width: 90%;
    max-width: 320px;

    margin: 48px auto 0;
    padding: 0;

    color: var(--colors-white);

    ${media.mdUp`
        flex-direction: row;

        max-width: 1180px;
        width: 100%;

        margin: 75px auto 0 auto;
        padding: 0 20px;
    `}
`;

export const Item = styled.div`
    ${media.mdUp`
        width: 25%;
    `}

    &:not(:last-child) {
        margin-bottom: 23px;

        ${media.mdUp`
            margin-bottom: 0;
            padding-right: 19px;
        `}
    }
`;

export const Heading = styled.h2`
    margin-bottom: 6px;

    font-size: 18px;
    line-height: 25px;

    ${media.mdUp`
        font-size: 20px;
        line-height: 30px;
    `}
`;
