import PropTypes from "prop-types";
import Text from "components/shared/text/Text";
import Button from "components/shared/button/Button";
import { useContactFormContext } from "lib/contactFormContext";
import { Container, Benefit, Title } from "./Benefits.styled";

function Benefits({ data }) {
    const { setShow, show } = useContactFormContext();
    return (
        <Container>
            {data?.map(benefit => {
                const hasLabel = !!benefit?.link?.label;
                const hasUrl = !!benefit?.link?.url;
                const isContactForm =
                    benefit?.link?.label?.toLowerCase().includes("contact") ||
                    benefit?.link?.label?.toLowerCase().includes("vraag");
                return (
                    <Benefit>
                        <Title
                            variant="tertiary"
                            leftAlignedMobile
                            leftAlignedDesktop
                        >
                            {benefit?.title}
                        </Title>
                        <Text leftAlignedMobile leftAlignedDesktop>
                            {benefit?.description}
                        </Text>
                        {hasLabel && (
                            <>
                                {hasUrl && !isContactForm ? (
                                    <Button
                                        variant="underline"
                                        element="a"
                                        href={benefit?.link?.url}
                                    >
                                        {benefit?.link?.label}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="underline"
                                        element="a"
                                        onClick={() => setShow(!show)}
                                    >
                                        {benefit?.link?.label}
                                    </Button>
                                )}
                            </>
                        )}
                    </Benefit>
                );
            })}
        </Container>
    );
}

Benefits.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        })
    )
};

Benefits.defaultProps = {
    data: [
        {
            title: "Betaal achteraf via factuur",
            description: `Je kunt je zakelijke bestelling eenvoudig plaatsen via onze bestelpagina.
                rbij krijg je de mogelijkheid om via iDeal, Payconiq, creditcard of achteraf
                via een factuur te betalen.`,

            link: { url: "/keuzekaart-bestellen", label: "Direct bestellen" }
        },
        {
            title: "Offerte binnen een uur!",
            description: `Op deze pagina kan je een offerte op maat aanvragen voor
                jouw bedrijf of organisatie. Je krijgt binnen een uur een passende offerte!
                Je kunt ons bereiken op maandag tot en met vrijdag tussen 09:30 – 12:30 & 13:00 – 17:00 op 020-261 28 78.`,
            link: {
                label: "Offerte aanvragen",
                url: "/zakelijk"
            }
        },
        {
            title: "Personalisatie",
            description: `Maak jouw cadeau compleet met jouw logo op de kaart. Of kies een mooie verpakking.
                Ook kunnen wij gepersonaliseerde sleeves voor jou maken. Benieuwd? Neem contact met ons op.`,
            link: {
                label: "Vraag naar de mogelijkheden",
                url: ""
            }
        },
        {
            title: "Goede persoonlijke service",
            description: `Op het gebied van zakelijke bestellingen is er vaak veel mogelijk,
                heb je bijvoorbeeld een goed idee over een verpakking? Let us know! `,
            link: {
                label: "Neem contact op",
                url: ""
            }
        }
    ]
};

export default Benefits;
