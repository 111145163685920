import PropTypes from "prop-types";
import NextImage from "next/image";
import Link from "next/link";
import { useMediaQueryContext } from "lib/mediaQueryContext";
import {
    HeroStyled,
    Container,
    Heading,
    Subheading,
    Buttons,
    Image,
    BackgroundImagesContainer,
    BackgroundImage,
    Card,
    Usps,
    Usp,
    UspImage
} from "./Hero.styled";
import Button from "../../shared/button/Button";
import TiltCard from "../../shared/tiltCard/TiltCard";

export default function Hero({ data }) {
    const { mobileView } = useMediaQueryContext();
    const mobileBackgroundImage = data?.background?.find(
        bg => bg.type === "MOBILE"
    );

    const desktopBackgroundImage = data?.background?.find(
        bg => bg.type === "DESKTOP"
    );

    return (
        <HeroStyled>
            <Container>
                <Heading>{data?.title}</Heading>
                <Subheading>
                    {data?.description && data?.description !== null
                        ? data?.description
                        : "Dé cadeaukaart om alles te geven"}
                </Subheading>
                <Buttons>
                    <Link
                        href="/keuzekaart-bestellen"
                        passHref
                        prefetch={false}
                    >
                        <Button variant="regular" element="a">
                            Nu bestellen
                        </Button>
                    </Link>
                    <Link href="#steps" passHref prefetch={false}>
                        <Button variant="underline" element="a">
                            Meer informatie
                        </Button>
                    </Link>
                </Buttons>
                <Image>
                    {mobileBackgroundImage?.url && desktopBackgroundImage?.url && (
                        <BackgroundImagesContainer>
                            <BackgroundImage>
                                <NextImage
                                    src={
                                        mobileView
                                            ? mobileBackgroundImage?.url
                                            : desktopBackgroundImage?.url
                                    }
                                    alt=""
                                    layout="fill"
                                    priority
                                />
                            </BackgroundImage>
                        </BackgroundImagesContainer>
                    )}
                    <Card>
                        {data?.image?.url && (
                            <TiltCard
                                url={data?.image?.url}
                                alt={data?.image?.alt}
                                borderRadius="35px"
                            />
                        )}
                    </Card>
                </Image>
                <Usps>
                    {data?.usps?.map(usp => (
                        <Usp key={JSON.stringify(usp)}>
                            <UspImage>
                                <NextImage
                                    src={
                                        usp?.images?.[0]?.url ??
                                        "/images/check-orange.svg"
                                    }
                                    alt={usp?.images?.[0]?.alt ?? ""}
                                    layout="fill"
                                    priority
                                />
                            </UspImage>
                            <span>{usp?.title}</span>
                        </Usp>
                    ))}
                </Usps>
            </Container>
        </HeroStyled>
    );
}

Hero.propTypes = {
    data: PropTypes.shape({
        background: PropTypes.arrayOf(
            PropTypes.shape({ url: PropTypes.string, type: PropTypes.string })
        ),
        description: PropTypes.string,
        image: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string
        }),
        title: PropTypes.string,
        usps: PropTypes.arrayOf(PropTypes.shape({}))
    })
};

Hero.defaultProps = {
    data: {
        background: [
            {
                url: "/images/sparkles.png",
                type: "MOBILE"
            },
            {
                url: "/images/sparkles-2.png",
                type: "DESKTOP"
            }
        ],
        image: {
            url: "/images/card.png",
            alt: "Nationale Keuze Cadeaukaart"
        },
        usps: [
            {
                title: "Voor 17:00 uur besteld = vandaag verstuurd ",
                images: [{ url: "/images/check-orange.svg", alt: "" }]
            },
            {
                title: "Gratis verpakt in een mooie cadeau-envelop",
                images: [{ url: "/images/check-orange.svg", alt: "" }]
            },
            {
                title: "Veilig betalen via iDEAL, etc.",
                images: [{ url: "/images/check-orange.svg", alt: "" }]
            }
        ],
        title: "De Nationale Keuze Cadeaukaart",
        subtitle: "Dé cadeaukaart om alles te geven"
    }
};
