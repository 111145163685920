import styled from "styled-components";
import media from "constants/media";

import SharedTitle from "components/shared/title/Title";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: benefits;

    ${media.mdUp`
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        max-width: 580px;
    `}
`;

export const Benefit = styled.div`
    margin: 0 auto 23px;

    text-align: left;

    ${media.mdUp`
        margin: 0;
        text-align: left;
    `}
`;

export const Title = styled(SharedTitle)`
    margin-bottom: 6px;
`;
