import styled from "styled-components";
import media from "constants/media";

export const LandingStyled = styled.div`
    min-height: 1200px;
    margin-bottom: -35px;
    padding-top: 50px;
    overflow: hidden;

    background: var(--colors-dark-blue);

    ${media.mdUp`
        padding-top: 90px;
        margin-bottom: 0;
    `}
`;
