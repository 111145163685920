import styled from "styled-components";
import media from "constants/media";

export const InstructionsStyled = styled.div`
    position: relative;
    z-index: 2;

    padding: 50px 0 40px;

    background: var(--colors-white);

    ${media.mdUp`
        margin-top: 135px;
        padding: 135px 0;
    `}
`;

export const Container = styled.div`
    width: 90%;
    max-width: 320px;

    margin: 0 auto;
    padding: 0;

    ${media.mdUp`
        width: 100%;
        max-width: 580px;
        padding: 0 20px;
    `}
`;

export const Heading = styled.h2`
    margin-bottom: 3px;

    font-size: 24px;
    line-height: 35px;
    text-align: center;

    ${media.mdUp`
        margin-bottom: 6px;

        font-size: 40px;
        line-height: 50px;
    `}
`;

export const Text = styled.p`
    margin-bottom: 11px;

    font-size: 14px;
    line-height: 25px;
    text-align: center;

    ${media.mdUp`
        margin-bottom: 19px;

        line-height: 30px;
    `}
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;

    a {
        margin-right: 9px;

        ${media.mdUp`
            margin-right: 18px;
        `}
    }

    ${media.mdUp`
        margin-bottom: 50px;
    `}
`;

export const Cards = styled.div`
    position: relative;

    text-align: center;
`;
