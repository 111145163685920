import PropTypes from "prop-types";
import Image from "next/image";
import { useMediaQueryContext } from "lib/mediaQueryContext";
import useWindowSize from "helpers/hooks/useWindowSize"; // CHECK TO REMOVE IT AND USE useMediaQueryContext
import { sizes } from "constants/media";
import { useInView } from "react-intersection-observer";
import SparklesImage from "public/images/sparkles.png";
import {
    StepsStyled,
    Heading,
    BackgroundContainer,
    BackgroundImage,
    Items,
    Step,
    Title,
    Number,
    Text
} from "./Steps.styled";

import Carousel from "../../shared/carousel/Carousel";

function Steps({ data }) {
    const windowSize = useWindowSize();
    const { desktopView, mobileView } = useMediaQueryContext();
    const { ref, inView } = useInView();

    const carouselItems = data?.[0]?.sections
        ?.slice(0, 3)
        .map((step, index) => (
            <Step key={JSON.stringify(step)}>
                <Title>
                    <Number>{index + 1}</Number>
                    {step?.title}
                </Title>
                <Text>{step?.description}</Text>
            </Step>
        ));

    return (
        <StepsStyled ref={ref} id="steps">
            <Heading>{data?.[0]?.title}</Heading>
            <BackgroundContainer>
                <BackgroundImage>
                    <Image src={SparklesImage} alt="" layout="fill" />
                </BackgroundImage>
            </BackgroundContainer>
            {mobileView && (
                <Items>
                    {inView && (
                        <Carousel
                            items={carouselItems}
                            slideWidth={
                                windowSize >= sizes.md ? "380px" : "260px"
                            }
                            noButtons
                        />
                    )}
                </Items>
            )}
            {desktopView && <Items>{carouselItems?.map(item => item)}</Items>}
        </StepsStyled>
    );
}

Steps.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            sections: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string
                })
            )
        })
    )
};

Steps.defaultProps = {
    data: [
        {
            title: "Zo werkt het",
            sections: [
                {
                    title: "Bepaal een waarde",
                    description:
                        "Jij kiest het saldo op de kaart, dit saldo mag in één keer of in delen worden verzilverd. Je kan ook meerdere kaarten met verschillende waardes bestellen."
                },
                {
                    title: "Persoonlijk maken",
                    description:
                        "Alleen een cadeaukaart ontvangen is al leuk, je maakt het nog leuker voor je ontvanger als je deze verpakt in een mooi doosje en/of tasje."
                },
                {
                    title: "Je ontvanger kiest",
                    description:
                        "Hij/zij mag kiezen uit 100+ verschillende cadeaus of goede doelen. Het saldo mag in delen worden ingewisseld voor cadeaukaarten."
                }
            ]
        }
    ]
};
export default Steps;
