import { forwardRef } from "react";
import NextImage from "next/image";
import PropTypes from "prop-types";
import { useMediaQueryContext } from "lib/mediaQueryContext";

import Button from "components/shared/button/Button";

import ClampedText from "components/shared/clampedText/ClampedText";
import {
    InformationStyled,
    Container,
    Heading,
    Content,
    MoreInfo,
    DoublerContainer,
    DoublerImageContainer,
    Title,
    Description,
    Text
} from "./Information.styled";

const Information = forwardRef(({ doubler, seo }, ref) => {
    const { mobileView, tabletView, desktopView } = useMediaQueryContext();

    return (
        <InformationStyled ref={ref}>
            <Container>
                <Heading>{seo?.title}</Heading>
                <Content>
                    <MoreInfo>
                        {(desktopView || tabletView) && (
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: seo?.description
                                }}
                                noMargin
                            />
                        )}
                        {mobileView && (
                            <ClampedText
                                text={seo?.description}
                                variant="primary"
                            />
                        )}
                    </MoreInfo>
                    {doubler && (
                        <DoublerContainer>
                            <DoublerImageContainer>
                                <NextImage
                                    src="/images/doubler-desktop.png"
                                    width={590}
                                    height={440}
                                />
                            </DoublerImageContainer>
                            <Title variant="tertiaryBig">
                                Verdubbel de waarde van je kaart
                            </Title>
                            <Description>
                                Maak kans op een verdubbeling van het bedrag op
                                je cadeaukaart. Vul meteen je kaartnummer in en
                                doe mee!
                            </Description>
                            <Button href="/verdubbelaar" element="a" passHref>
                                Ik doe mee
                            </Button>
                        </DoublerContainer>
                    )}
                </Content>
            </Container>
        </InformationStyled>
    );
});

Information.propTypes = {
    doubler: PropTypes.bool,
    seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string
    })
};

Information.defaultProps = {
    doubler: false,
    seo: {
        title: "Keuze uit meer dan 100+ cadeaukaarten",
        description: `Met de Keuze Cadeaukaart weet je zeker dat het cadeau in de smaak valt. De ontvanger kiest namelijk zelf zijn of haar favoriete cadeaubon, je geeft de keuze uit meer dan 100 opties, zoals <a href="https://www.diner-cadeau.nl/"> Diner Cadeau </a>, <a href="https://www.saunawellnesscadeaukaart.nl/">Keuze Cadeaukaart</a> voor het ultieme cadeau! <br/> <br/> De keuze mogelijkheden zijn eindeloos met de Keuze Cadeaukaart en dat maakt dit cadeau zo uitermate geschikt voor nagenoeg elke situatie en elke gelegenheid. Er bestaat bovendien zelfs de mogelijkheid om de kaart in te wisselen voor donatie aan een goed doel! En natuurlijk kan er ook gewoon gekozen worden voor een dinerbon of een cadeaukaart die is in te wisselen voor een heerlijk dagje uit. Zo zit er dus altijd wel iets tussen voor iedereen! De Keuze cadeaukaart is dus een geschikt cadeau voor elk moment, of het nu gaat om een geschenk aan een familielid of voor bijvoorbeeld voor een collega: hiermee sla je nooit de plank mis!`
    }
};

export default Information;
