import Link from "next/link";
import Button from "../../shared/button/Button";
import { LinksStyled, Item, Heading } from "./Links.styled";

export default function Links() {
    return (
        <LinksStyled>
            <Item>
                <Heading>Eindeloos personaliseren</Heading>
                <Link href="/keuzekaart-bestellen" passHref prefetch={false}>
                    <Button variant="underline" element="a">
                        Maak jouw cadeau
                    </Button>
                </Link>
            </Item>
            <Item>
                <Heading>
                    Vrije keuze uit 100+ cadeaukaarten en goede doelen
                </Heading>
                <Button
                    variant="underline"
                    element="a"
                    href="https://www.cadeaubon.nl/cadeaukaart-keuze/step-one"
                    target="_blank"
                    rel="noopener norefer"
                >
                    Inwisselen
                </Button>
            </Item>
            <Item>
                <Heading>Gratis verpakt in een mooie cadeau-envelop</Heading>
                <Link href="/keuzekaart-bestellen" passHref prefetch={false}>
                    <Button variant="underline" element="a">
                        Of kies een speciale verpakking
                    </Button>
                </Link>
            </Item>
            <Item>
                <Heading>Waarde van € 5,- tot € 150,-</Heading>
                <Button variant="underline" element="a" href="/saldocheck">
                    Check jouw saldo
                </Button>
            </Item>
        </LinksStyled>
    );
}
