import Image from "next/image";
import { useMediaQueryContext } from "lib/mediaQueryContext";
import BrandsRibbon from "components/svg/BrandsRibbon";
import Button from "../../shared/button/Button";
import {
    BrandsStyled,
    Container,
    Heading,
    Ribbon,
    Logos,
    Logo,
    Buttons
} from "./Brands.styled";

function Brands() {
    const { desktopView } = useMediaQueryContext();
    return (
        <BrandsStyled>
            <Container>
                <Heading>
                    De Nationale Keuze Cadeaukaart is in te wisselen voor onder
                    andere:
                </Heading>
                <Ribbon>
                    <BrandsRibbon />
                </Ribbon>
                <Logos>
                    <Logo>
                        <Image
                            src="/images/logo-zalando.svg"
                            alt="Zalando"
                            layout="fill"
                            objectFit="contain"
                            priority
                        />
                    </Logo>
                    <Logo>
                        <Image
                            src="/images/logo-douglas.svg"
                            alt="Douglas"
                            layout="fill"
                            priority
                            objectFit="contain"
                        />
                    </Logo>
                    <Logo>
                        <Image
                            src="/images/logo-bol.svg"
                            alt="Bol.com"
                            layout="fill"
                            priority
                            objectFit="contain"
                        />
                    </Logo>
                    <Logo>
                        <Image
                            src="/images/logo-iciparisxl.svg"
                            alt="ICI PARIS XL"
                            layout="fill"
                            priority
                            objectFit="contain"
                        />
                    </Logo>
                    <Logo>
                        <Image
                            src="/images/logo-bijenkorf.svg"
                            alt="de Bijenkorf"
                            layout="fill"
                            priority
                            objectFit="contain"
                        />
                    </Logo>
                    <Logo>
                        <Image
                            src="/images/logo-wehkamp.svg"
                            alt="Wehkamp"
                            layout="fill"
                            priority
                            objectFit="contain"
                        />
                    </Logo>
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-dinercadeau.svg"
                                alt="Diner Cadeau"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-footlocker.svg"
                                alt="Foot Locker"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-nike.svg"
                                alt="Nike"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-hema.svg"
                                alt="HEMA"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-hunkemoller.svg"
                                alt="Hunkemöller"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-nationalebiosbon.svg"
                                alt="Nationale Bioscoop Bon"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-decathlon.svg"
                                alt="Decathlon"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-kunstcultuur.svg"
                                alt="Nationale Kunst & Cultuur Cadeaukaart"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-gamma.svg"
                                alt="Gamma"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-prenatal.png"
                                alt="Prénatal"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-shoeby.svg"
                                alt="Shoeby"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-bladcadeau.png"
                                alt="Blad Cadeau"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-vtwonen.svg"
                                alt="vtwonen"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-weekendjeweg.png"
                                alt="Weekendje Weg"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                    {desktopView && (
                        <Logo>
                            <Image
                                src="/images/logo-efteling.svg"
                                alt="Efteling"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </Logo>
                    )}
                </Logos>
                <Buttons>
                    <Button
                        variant="regular"
                        element="a"
                        href="https://www.cadeaubon.nl/cadeaukaart-keuze/step-one"
                        target="_blank"
                        rel="noopener norefer"
                    >
                        Inwisselen
                    </Button>
                    <Button
                        variant="underline"
                        element="a"
                        href="/verkooppunten"
                    >
                        Bekijk alle mogelijkheden
                    </Button>
                </Buttons>
            </Container>
        </BrandsStyled>
    );
}

export default Brands;
