import styled from "styled-components";
import media from "constants/media";

export const Primary = styled.h1``;

export const PrimaryBig = styled.h1`
    overflow: hidden;

    font-weight: 300;
    font-size: 35px;
    line-height: 40px;

    text-align: ${({ leftAlignedMobile }) =>
        leftAlignedMobile ? "left" : "center"};
    text-overflow: ellipsis;

    ${media.mdUp`
        font-size: 60px;
        line-height: 70px;
        text-align: ${({ leftAlignedDesktop }) =>
            leftAlignedDesktop ? "left" : "center"};
    `}
`;

export const Secondary = styled.h2`
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;

    text-align: ${({ leftAlignedMobile }) =>
        leftAlignedMobile ? "left" : "center"};

    ${media.mdUp`
        font-size: 40px;
        line-height: 50px;
        text-align: ${({ leftAlignedDesktop }) =>
            leftAlignedDesktop ? "left" : "center"};
    `}
`;

export const SecondaryLarge = styled.h2`
    font-weight: bold;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;

    text-align: ${({ leftAlignedMobile }) =>
        leftAlignedMobile ? "left" : "center"};

    ${media.mdUp`
        font-size: 50px;
        line-height: 60px;
        text-align: ${({ leftAlignedDesktop }) =>
            leftAlignedDesktop ? "left" : "center"};
    `}
`;

export const SecondarySmall = styled.h2`
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;

    text-align: ${({ leftAlignedMobile }) =>
        leftAlignedMobile ? "left" : "center"};

    ${media.mdUp`
        font-size: 30px;
        line-height: 40px;
        text-align: ${({ leftAlignedDesktop }) =>
            leftAlignedDesktop ? "left" : "center"};
    `}
`;

export const Tertiary = styled.h3`
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;

    text-align: ${({ leftAlignedMobile }) =>
        leftAlignedMobile ? "left" : "center"};

    ${media.mdUp`
        font-size: 20px;
        line-height: 30px;

        text-align: ${({ leftAlignedDesktop }) =>
            leftAlignedDesktop ? "left" : "center"};
    `}
`;

export const TertiaryBig = styled(Tertiary)`
    &&& {
        font-size: 20px;

        ${media.mdUp`
            font-size: 25px;
            line-height: 35px;
        `}
    }
`;
