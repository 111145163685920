import media from "constants/media";
import NextImage from "next/image";
import styled from "styled-components";

export const Container = styled.div`
    position: relative;

    width: 280px;
    height: 182px;

    ${media.mdUp`
        width: 499px;
        height: 324px;
    `}
`;

export const TiltCardStyled = styled.div`
    overflow: hidden;

    border-radius: ${({ borderRadius }) => borderRadius};

    user-select: none;
`;

export const Image = styled(NextImage)`
    width: 100%;
`;
