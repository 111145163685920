import styled, { css } from "styled-components";
import media from "constants/media";

export const CarouselStyled = styled.div`
    width: 100%;

    margin-bottom: ${({ noDots }) => !noDots && "40px"};

    .slider {
        outline: none;
    }

    .slider-frame {
        padding: 0 0 30px !important; /* stylelint-disable-line declaration-no-important */
    }

    .slider-list {
        cursor: grab !important; /* stylelint-disable-line declaration-no-important */
    }

    .slider-slide {
        outline: 0;
    }

    .slider-control-centerleft,
    .slider-control-centerright {
        display: ${({ noButtons }) => (noButtons ? "none" : "flex")};
        align-items: center;
        justify-content: center;
        height: 100%;

        transition: 0.3s all ease-in-out;
    }

    .slider-control-bottomcenter {
        ${({ noDots }) =>
            noDots
                ? css`
                      display: none !important;
                  `
                : css`
                      display: ${({ isLoading }) =>
                          isLoading ? "none !important" : "block !important"};
                  `}
    }

    .slider-dots-container-custom {
        top: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    .slider-dots-dot-custom {
        display: block;
        width: 10px;
        height: 5px;

        padding: 0;

        /* stylelint-disable-next-line declaration-no-important */
        background: var(--colors-white) !important;

        border-radius: 2px;
        outline: none;

        opacity: 0.2 !important; /* stylelint-disable-line declaration-no-important */

        transition: 0.3s all ease-in-out;

        svg {
            display: none;
        }

        &:hover {
            opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    .paging-item {
        &:not(:last-child) {
            margin-right: 5px;
        }

        &.active {
            .slider-dots-dot-custom {
                opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
            }
        }
    }
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 54px;
    height: 54px;
    margin-top: 0;

    background: rgba(255, 255, 255, 0.8);
    border: 0;
    border-radius: 50%;
    outline: none;

    cursor: pointer;

    transition: 0.3s all ease-in-out;

    ${({ left, buttonsOutside }) =>
        left &&
        css`
            margin-left: ${buttonsOutside ? "0" : "20px"};

            ${media.mdUp`
                margin-left: ${buttonsOutside ? "-45px" : "20px"};
            `}

            &:hover:not(:disabled) {
                transform: translateX(-3px);
            }
        `}

    ${({ right }) =>
        right &&
        css`
            margin-right: 20px;

            &:hover:not(:disabled) {
                transform: translateX(3px);
            }
        `}

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;
