import styled from "styled-components";
import media from "constants/media";

export const BrandsStyled = styled.div`
    margin-top: 38px;

    background: var(--colors-white);

    ${media.mdUp`
        max-width: 1180px;

        margin: 125px auto 0 auto;

        border-radius: 20px;
    `}
`;

export const Container = styled.div`
    position: relative;

    width: 90%;
    max-width: 320px;
    margin: auto;

    margin: 0 auto;
    padding: 33px 0 40px;

    text-align: center;

    ${media.mdUp`
        max-width: none;

        padding: 60px 95px;
    `}
`;

export const Heading = styled.h2`
    margin-bottom: 10px;
    padding-right: 40px;

    font-size: 18px;
    line-height: 25px;

    text-align: left;

    ${media.mdUp`
        margin-bottom: 25px;
        padding-right: 0;

        text-align: center;
    `}
`;

export const Ribbon = styled.div`
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 2;

    width: 46px;
    height: 50px;

    ${media.mdUp`
        right: 40px;

        width: 58px;
    `}
`;

export const Logos = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 16px;
`;

export const Logo = styled.div`
    position: relative;

    width: 70px;
    height: 35px;

    margin: 0 10px 6px;

    ${media.mdUp`
        width: 12%;
        height: 50px;

        margin: 0 1% 20px;
    `}
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${media.mdUp`
        flex-direction: row;
    `}

    a:not(:last-child) {
        margin-bottom: 20px;

        ${media.mdUp`
            margin: 0 19px 0 0;
        `}
    }
`;
