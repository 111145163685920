import PropTypes from "prop-types";
import Image from "next/image";
import Link from "next/link";
import { useMediaQueryContext } from "lib/mediaQueryContext";

import Title from "components/shared/title/Title";

import {
    Container,
    Wrapper,
    Right,
    Left,
    Description,
    Button,
    ImageContainer
} from "./Resellers.styled";

export default function Resellers({ resellers }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Container>
            <Wrapper>
                <Left>
                    <Title
                        variant="secondary"
                        leftAlignedMobile
                        leftAlignedDesktop
                    >
                        Verkooppunten
                    </Title>
                    <Description leftAlignedMobile leftAlignedDesktop>
                        De Keuze Cadeaukaart kun je ook altijd in de winkel
                        kopen. Vind een winkel bij jou in de buurt op basis van
                        jouw postcode.
                    </Description>
                    <Link href="/verdubbelaar" passHref prefetch={false}>
                        <Button element="a" variant="white">
                            Vind een winkel in de buurt
                        </Button>
                    </Link>
                </Left>
                <Right>
                    {resellers?.map(reseller => (
                        <ImageContainer>
                            <Image
                                src={reseller?.src}
                                width={mobileView ? 70 : 120}
                                height={mobileView ? 35 : 60}
                                alt={reseller?.alt}
                            />
                        </ImageContainer>
                    ))}
                </Right>
            </Wrapper>
        </Container>
    );
}

Resellers.propTypes = {
    resellers: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number
        })
    )
};

Resellers.defaultProps = {
    resellers: [
        {
            src: "/images/logo-esso-white.svg",
            alt: "Esso logo"
        },
        {
            src: "/images/logo-dirk-white.svg",
            alt: "Dirk logo"
        },
        {
            src: "/images/logo-hoogvliet-white.svg",
            alt: "Hoogvliet logo"
        },
        {
            src: "/images/logo-kiosk-white.svg",
            alt: "Kiosk logo"
        },
        {
            src: "/images/logo-primera-white.svg",
            alt: "Primera logo"
        },
        {
            src: "/images/logo-shell-white.svg",
            alt: "Shell logo"
        }
    ]
};
