import styled from "styled-components";
import media from "constants/media";
import SharedTitle from "components/shared/title/Title";

export const Title = styled(SharedTitle)``;

export const Text = styled.div`
    max-height: ${({ lines }) => 20 * lines}px;

    margin: 0 auto 14px;
    overflow: hidden;

    font-size: 14px;
    line-height: 20px;

    transition: max-height 0.6s ease;

    a {
        color: var(--colors-bright-orange);
        text-decoration: none;
    }

    ${media.mdUp`
        line-height: 25px;
        font-size: 15px;
        max-height: ${({ lines }) => 25 * lines}px;
    `}
`;

export const Controls = styled.div`
    position: relative;
`;

export const Gradient = styled.div`
    position: absolute;
    top: -100px;
    left: 0;

    width: 100%;
    height: 100px;

    background: linear-gradient(
        180deg,
        rgba(4, 35, 57, 0) 0%,
        var(--colors-dark-blue) 100%
    );

    transform: scaleY(${({ isShowingMore }) => (isShowingMore ? "0.1" : "1")});
    transform-origin: bottom center;

    transition: all 0.6s ease;
`;
