import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

// Show can be either a boolean or an object to pass options to the form modal
export const ContactFormContext = createContext({
    show: false,
    setShow: () => {}
});

export function ContactFormProvider({ children }) {
    const [show, setShow] = useState(false);

    return (
        <ContactFormContext.Provider value={{ show, setShow }}>
            {children}
        </ContactFormContext.Provider>
    );
}

ContactFormProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export function useContactFormContext() {
    const context = useContext(ContactFormContext);

    if (!context) {
        throw new Error("useCartContext can only be used within CartProvider");
    }

    return context;
}
